import React, { useState } from 'react';
import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { AttachFile, BubbleChart, Close, Description, ImportContacts, Settings, Wallpaper } from "@mui/icons-material";
import useStyles from './ChartingMenu.styles';
import clsx from 'clsx';
import { useDispatch, useSelector } from "react-redux";
import { actions } from '../Chart.redux';
import { actions as fileActions } from 'components/FileManager/FileManager.redux';
import FileManager from 'components/FileManager';
import ReportsDialog from './ReportsDialog';
import NewReportDialog from './NewReportDialog';



const MenuExtras = () => {
	const classes               = useStyles(),
		  [ anchor, setAnchor ] = useState(null),
		  dispatch              = useDispatch(),
		  selected              = useSelector(state => state.chart.toolbar.selectedButton === 'extras'),
		  tool                  = useSelector(state => state.chart.toolbar.selectedTool),
		  reportModalStatus     = useSelector(state => state.chart.reportModalStatus),
		  validTools            = [ 'visitInfo' ],
		  visitID               = useSelector(state => state.chart.visit_id),
		  clientID              = useSelector(state => state.chart.client_id),
		  patientID             = useSelector(state => state.chart.patient_id);



	const handleClose              = () => setAnchor(null),


		  handleOpen               = (e) => {
			  setAnchor(e.currentTarget);

			  let shouldUpdate = false,
				  update       = {};

			  if(!selected) {
				  shouldUpdate = true;
				  update.selectedButton = '';
				  update.selectedTool = null;
			  }

			  if(selected && validTools.includes(tool)) {
				  shouldUpdate = false;
			  }

			  if(selected && !validTools.includes(tool)) {
				  shouldUpdate = true;
				  update.selectedTool = null;
			  }

			  if(shouldUpdate) {
				  dispatch(actions.setToolbar(update));
			  }
		  },


		  toggleTool               = name => e => {
			  dispatch(actions.setToolbar({
				  selectedTool: name,
				  selectedButton: 'extras',
			  }));
			  setAnchor(null);
		  },


		  handleReportsDialogClose = () => dispatch(actions.setState({ reportModalStatus: null })),

		  handleNewReport          = () => dispatch(actions.setState({ reportModalStatus: 'new' })),


		  toolIcons                = {
			  markMissing: <Close />,
			  probe: <BubbleChart />,
		  };



	return (
		<>
			<Button
				variant={selected
					? "contained"
					: "outlined"}
				color={selected
					? "primary"
					: undefined}
				className={clsx(classes.buttonMarginRight, classes.buttonFloat)}
				startIcon={
					toolIcons[ tool ] !== undefined
						? toolIcons[ tool ]
						: <Settings />
				}
				onClick={handleOpen}
			>
				Extras
			</Button>

			<Menu
				anchorEl={anchor}
				open={Boolean(anchor)}
				onClose={handleClose}
			>
				<MenuItem
					onClick={toggleTool('visitInfo')}
					className={clsx({
						[ classes.activeItem ]: tool === 'visitInfo',
					})}
				>
					<ListItemIcon><ImportContacts /></ListItemIcon>
					<ListItemText>Visit Info</ListItemText>
				</MenuItem>

				<MenuItem
					onClick={() => {
						dispatch(fileActions.setState({ open: true, page: 'visit' }));
						dispatch(fileActions.setBrowseOptions({ visit_id: visitID }));
					}}
				>
					<ListItemIcon><AttachFile /></ListItemIcon>
					<ListItemText>File Manager</ListItemText>
				</MenuItem>

				<MenuItem
					onClick={toggleTool('snapshot')}
					className={clsx({
						[ classes.activeItem ]: tool === 'snapshot',
					})}
				>
					<ListItemIcon><Wallpaper /></ListItemIcon>
					<ListItemText>Save Chart Image</ListItemText>
				</MenuItem>

				<MenuItem
					onClick={() => {
						dispatch(actions.setState({ reportModalStatus: 'list' }));
						handleClose();
					}}
				>
					<ListItemIcon><Description /></ListItemIcon>
					<ListItemText>Reports</ListItemText>
				</MenuItem>

			</Menu>

			<FileManager
				visit_id={visitID}
				patient_id={patientID}
				client_id={clientID}
			/>

			{reportModalStatus === 'list' &&
				<ReportsDialog
					handleClose={handleReportsDialogClose}
					handleNewReport={handleNewReport}
				/>
			}


			{reportModalStatus === 'new' &&
				<NewReportDialog
					handleClose={handleReportsDialogClose}
				/>
			}
		</>
	);
};



export default MenuExtras;
