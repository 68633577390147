import React, { useEffect } from 'react';
import { Link, Route, useLocation } from 'react-router-dom';
import DashboardCard from "components/DashboardCard";
import {
	AutoFixHigh,
	ContactMail,
	CreditCard,
	DataObject,
	ExpandLess,
	ExpandMore,
	Feed,
	FormatPaint,
	Lock,
	ManageAccounts,
	Person,
	Place,
	SettingsApplications,
	Title,
} from "@mui/icons-material";
import { Collapse, Grid as MUIGrid, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

import ClinicSettingsHome from './ClinicSettingsHome';
import NameAndLogo from './NameAndLogo';
import ContactInfo from './ContactInfo';
import CustomCodes from './CustomCodes';
import DefaultCodes from './DefaultCodes';
import ChartAppearance from './ChartAppearance';
import UserManagement from './UserManagement';
import Permissions from './Permissions';
import Billing from './Billing';
import { useClinicSettingsContext } from './ClinicSettingsContext';



const Whatever = () => {
	return <div>hi</div>;
};
const Clients = () => {
	const { activeMenu, setActiveMenu } = useClinicSettingsContext();
	const { pathname } = useLocation();
	
	// Reopen the correct menu when navigating between pages
	useEffect(() => {
		switch(pathname) {
			case '/clinic-settings/name-and-logo':
			case '/clinic-settings/contact-info':
				setActiveMenu('clinicInfo');
				break;
			case '/clinic-settings/custom-codes':
			case '/clinic-settings/default-codes':
			case '/clinic-settings/chart-appearance':
				setActiveMenu('chartingOptions');
				break;
			case '/clinic-settings/users':
			case '/clinic-settings/permissions':
				setActiveMenu('userManagement');
				break;
			default:
				setActiveMenu(null);
		}
	}, [ pathname, setActiveMenu ]);


	return (<MUIGrid container spacing={4}>
		<MUIGrid item xs={12} lg={4} xl={3}>
			<DashboardCard
				color={'orange'}
				icon={<SettingsApplications />}
				topContent={"Clinic Settings"}
				size="xl"
			>
				<List>
					<ListItemButton
						onClick={() => setActiveMenu(activeMenu === 'clinicInfo'
							? null
							: 'clinicInfo',
						)}
					>
						<ListItemIcon><ContactMail /></ListItemIcon>
						<ListItemText primary="Clinic Details" />
						{activeMenu === 'clinicInfo'
							? <ExpandLess />
							: <ExpandMore />
						}
					</ListItemButton>

					<Collapse in={activeMenu === 'clinicInfo'}>
						<List component="div" disablePadding dense>
							<ListItemButton sx={{ pl: 6 }} component={Link} to="/clinic-settings/name-and-logo">
								<ListItemIcon><Title /></ListItemIcon>
								<ListItemText primary="Name and Logo" />
							</ListItemButton>

							<ListItemButton sx={{ pl: 6 }} component={Link} to="/clinic-settings/contact-info">
								<ListItemIcon><Place /></ListItemIcon>
								<ListItemText primary="Address and Contact Info" />
							</ListItemButton>
						</List>
					</Collapse>


					<ListItemButton
						onClick={() => setActiveMenu(activeMenu === 'chartingOptions'
							? null
							: 'chartingOptions',
						)}
					>
						<ListItemIcon><Feed /></ListItemIcon>
						<ListItemText primary="Charting Options" />
						{activeMenu === 'chartingOptions'
							? <ExpandLess />
							: <ExpandMore />
						}
					</ListItemButton>

					<Collapse in={activeMenu === 'chartingOptions'}>
						<List component="div" disablePadding dense>
							<ListItemButton sx={{ pl: 6 }} component={Link} to="/clinic-settings/custom-codes">
								<ListItemIcon><DataObject /></ListItemIcon>
								<ListItemText primary="Custom Codes" />
							</ListItemButton>

							<ListItemButton sx={{ pl: 6 }} component={Link} to="/clinic-settings/default-codes">
								<ListItemIcon><AutoFixHigh /></ListItemIcon>
								<ListItemText primary="Default Codes" />
							</ListItemButton>

							<ListItemButton sx={{ pl: 6 }} component={Link} to="/clinic-settings/chart-appearance">
								<ListItemIcon><FormatPaint /></ListItemIcon>
								<ListItemText primary="Appearance" />
							</ListItemButton>
						</List>
					</Collapse>


					<ListItemButton
						onClick={() => setActiveMenu(activeMenu === 'userManagement'
							? null
							: 'userManagement',
						)}
					>
						<ListItemIcon><ManageAccounts /></ListItemIcon>
						<ListItemText primary="User Management" />
						{activeMenu === 'userManagement'
							? <ExpandLess />
							: <ExpandMore />
						}
					</ListItemButton>

					<Collapse in={activeMenu === 'userManagement'}>
						<List component="div" disablePadding dense>
							<ListItemButton sx={{ pl: 6 }} component={Link} to="/clinic-settings/users">
								<ListItemIcon><Person /></ListItemIcon>
								<ListItemText primary={'Users'} />
							</ListItemButton>

							<ListItemButton sx={{ pl: 6 }} component={Link} to="/clinic-settings/permissions">
								<ListItemIcon><Lock /></ListItemIcon>
								<ListItemText primary={'Permissions'} />
							</ListItemButton>
						</List>
					</Collapse>


					<ListItemButton component={Link} to="/clinic-settings/billing">
						<ListItemIcon><CreditCard /></ListItemIcon>
						<ListItemText primary={'Billing'} />
					</ListItemButton>

				</List>
			</DashboardCard>
		</MUIGrid>

		<MUIGrid item xs={12} lg={8} xl={9}>
			<Route path="/clinic-settings" exact render={ClinicSettingsHome} />
			<Route path="/clinic-settings/name-and-logo" exact component={NameAndLogo} />
			<Route path="/clinic-settings/contact-info" exact component={ContactInfo} />
			<Route path="/clinic-settings/default-codes" exact component={DefaultCodes} />
			<Route path="/clinic-settings/custom-codes" exact component={CustomCodes} />
			<Route path="/clinic-settings/chart-appearance" exact component={ChartAppearance} />
			<Route path="/clinic-settings/users" exact component={UserManagement} />
			<Route path="/clinic-settings/permissions" exact component={Permissions} />
			<Route path="/clinic-settings/billing" exact component={Billing} />
		</MUIGrid>
	</MUIGrid>);

};



export default Clients;
