import React, { createContext, useContext, useState } from 'react';



export const ClinicSettingsContext = createContext({
	activeMenu: null,
	setActiveMenu: (value) => {
		return null;
	},
});


export const ClinicSettingsContextProvider = ({ children }) => {
	const [ activeMenu, setActiveMenu ] = useState(null);

	return (
		<ClinicSettingsContext.Provider value={{ activeMenu, setActiveMenu }}>
			{children}
		</ClinicSettingsContext.Provider>
	);
};


export const useClinicSettingsContext = () => {
	return useContext(ClinicSettingsContext);
};
