import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { Title } from '@mui/icons-material';
import DashboardCard from 'components/DashboardCard';
import api from 'api';
import { enqueueSnackbar } from 'notistack';
import FileManager from 'components/FileManager';
import { useDispatch } from 'react-redux';
import { actions as fileActions } from 'components/FileManager/FileManager.redux';



const NameAndLogo = () => {
	const dispatch = useDispatch();
	const [ loaded, setLoaded ] = useState(false);
	const [ clinicData, setClinicData ] = useState({ name: '', logo: null });
	const [ saving, setSaving ] = useState(false);
	const openFileManager = () => dispatch(fileActions.setState({ open: true, page: 'upload' }));


	const saveName = useCallback(async () => {
		setSaving(true);

		try {
			await api.put('/clinic-settings/update/name', { clinic_name: clinicData?.name });
			enqueueSnackbar(`Clinic name updated!`, {
				variant: 'success',
				autoHideDuration: 2500,
			});
		} catch(err) {
			console.error(err);
			enqueueSnackbar(`An error occurred while saving.`, {
				variant: 'error',
				autoHideDuration: 2500,
			});
		}

		setSaving(false);
	}, [ clinicData?.name, setSaving ]);


	const updateName = useCallback(e => {
		setClinicData((currentValues => ({
			...currentValues,
			name: e.target.value,
		})));
	}, [ setClinicData ]);


	const handleChangeLogo = useCallback(async (file) => {
		setSaving(true);
		dispatch(fileActions.setState({ open: false }));

		try {
			const result = await api.put(
				'/clinic-settings/update/logo',
				{ file_id: file.file_id },
			);

			setClinicData(currentValues => ({
				...currentValues,
				logo: result.data.clinic_logo,
			}));

			enqueueSnackbar(`Clinic logo updated!`, {
				variant: 'success',
				autoHideDuration: 2500,
			});
		} catch(err) {
			console.error(err);
			enqueueSnackbar(`An error occurred while saving.`, {
				variant: 'error',
				autoHideDuration: 2500,
			});
		}

		setSaving(false);
	}, [ dispatch, setClinicData, setSaving ]);

	useEffect(() => {
		api.get('/clinic-settings/name-logo')
			.then(res => {
				setClinicData({
					name: res.data?.clinic?.name || '',
					logo: res.data?.clinic?.logo || null,
				});
			})
			.catch(err => console.error(err))
			.finally(() => setLoaded(true));
	}, []);

	return (
		<DashboardCard
			color={'orange'}
			icon={<Title />}
			topContent={"Clinic Name and Logo"}
			size="xl"
		>
			<Grid container spacing={2} sx={{ marginTop: 1 }}>
				<Grid item xs={12} lg={6}>
					<Typography variant="body2">
						Please enter the name of your clinic as you would like it to appear on reports.
					</Typography>
					<Box sx={{ marginTop: '16px' }}>
						<TextField
							placeholder="Enter the name of your clinic."
							label="Clinic name"
							value={clinicData.name}
							onChange={updateName}
							disabled={!loaded}
							sx={{ width: '320px' }}
						/>
						<Button
							variant="contained"
							sx={{ marginLeft: 2 }}
							disabled={!loaded || saving || !clinicData?.name}
							onClick={saveName}
						>
							Save
						</Button>
					</Box>
				</Grid>
				<Grid item xs={12} lg={6}>
					<Typography variant="body2">
						Click in the box below to select a new image to use as your logo.
					</Typography>
					<Box
						sx={{
							maxWidth: '400px',
							maxHeight: '300px',
							border: '3px dashed #858585',
							borderRadius: '12px',
						}}
						onClick={openFileManager}
					>
						{!!clinicData.logo &&
							<img
								src={clinicData.logo}
								style={{ maxWidth: '380px', maxHeight: '280px', paddingLeft: '10px' }}
							/>}
					</Box>

					<FileManager
						selectActions={[ {
							name: 'setAsLogo',
							label: 'Set as logo',
							handler: handleChangeLogo,
						} ]}
					/>
				</Grid>
			</Grid>

		</DashboardCard>
	);
};


export default NameAndLogo;
