import React, { useRef, useState } from 'react';
import { Button, Divider, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Popover } from "@mui/material";
import {
	CheckBoxOutlineBlank as EmptyCheck,
	ChevronRight,
	CheckBox,
	Layers,
	RadioButtonChecked,
	RadioButtonUnchecked,
} from "@mui/icons-material";
import useStyles from './ChartingMenu.styles';
import clsx from 'clsx';
import { useDispatch, useSelector } from "react-redux";
import { actions } from '../Chart.redux';



const MenuLayers = () => {
	const classes                             = useStyles(),
		  [ anchor, setAnchor ]               = useState(null),
		  [ submenuAnchor, setSubmenuAnchor ] = useState(null),
		  [ submenu, setSubmenu ]             = useState(null),
		  dispatch                            = useDispatch(),
		  layers                              = useSelector(state => state.chart.layers),
		  popoverRef                          = useRef(null);

	const handleClose = () => setAnchor(null),
		  handleOpen  = (e) => setAnchor(e.currentTarget),
		  toggleLayer = name => e => {
			  // Special cases for soft tissue and sublingual, as only one of them should be visible at a time
			  // They're fake radio buttons, basically
			  if(name === 'softTissue' && layers.sublingual && !layers.softTissue) {
				  dispatch(actions.toggleLayer(name));
				  dispatch(actions.toggleLayer('sublingual'));
			  } else if(name === 'sublingual' && !layers.sublingual && layers.softTissue) {
				  dispatch(actions.toggleLayer(name));
				  dispatch(actions.toggleLayer('softTissue'));
			  } else {
				  dispatch(actions.toggleLayer(name));
			  }
		  };

	const setOpenSubmenu = submenuName => e => {
		setSubmenuAnchor(e.currentTarget);
		setSubmenu(submenuName);
	};


	return (
		<>
			<Button
				variant="outlined"
				className={clsx(classes.buttonMarginRight, classes.buttonFloat)}
				startIcon={<Layers />}
				onClick={handleOpen}
			>
				Layers
			</Button>
			<Menu
				anchorEl={anchor}
				open={Boolean(anchor)}
				onClose={handleClose}
			>
				<MenuItem onClick={setOpenSubmenu('teeth')}>
					<ListItemText>Teeth</ListItemText>
					<ListItemIcon><ChevronRight /></ListItemIcon>
				</MenuItem>

				<MenuItem onClick={setOpenSubmenu('values')}>
					<ListItemText>Charting values</ListItemText>
					<ListItemIcon><ChevronRight /></ListItemIcon>
				</MenuItem>

				<MenuItem onClick={setOpenSubmenu('markup')}>
					<ListItemText>Markup</ListItemText>
					<ListItemIcon><ChevronRight /></ListItemIcon>
				</MenuItem>

				<MenuItem onClick={setOpenSubmenu('occlusal')}>
					<ListItemText>Occlusal view</ListItemText>
					<ListItemIcon><ChevronRight /></ListItemIcon>
				</MenuItem>
			</Menu>

			<Popover
				id="layer-submenu"
				anchorEl={submenuAnchor}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				open={Boolean(submenuAnchor)}
				onClose={() => {
					setSubmenu(null);
					setSubmenuAnchor(null);
				}}

			>
				{submenu === 'teeth' &&
					<MenuList>
						<MenuItem onClick={toggleLayer('adultTeeth')}>
							<ListItemIcon>{layers.adultTeeth
								? <CheckBox />
								: <EmptyCheck />}</ListItemIcon>
							<ListItemText>Adult Teeth</ListItemText>
						</MenuItem>

						<MenuItem onClick={toggleLayer('deciduousTeeth')}>
							<ListItemIcon>{layers.deciduousTeeth
								? <CheckBox />
								: <EmptyCheck />}</ListItemIcon>
							<ListItemText>Deciduous Teeth</ListItemText>
						</MenuItem>

						<MenuItem onClick={toggleLayer('missingTeethAdult')}>
							<ListItemIcon>{layers.missingTeethAdult
								? <CheckBox />
								: <EmptyCheck />}</ListItemIcon>
							<ListItemText>Missing Teeth (Adult)</ListItemText>
						</MenuItem>

						<MenuItem onClick={toggleLayer('missingTeethDeciduous')}>
							<ListItemIcon>{layers.missingTeethDeciduous
								? <CheckBox />
								: <EmptyCheck />}</ListItemIcon>
							<ListItemText>Missing Teeth (Deciduous)</ListItemText>
						</MenuItem>
					</MenuList>
				}

				{submenu === 'values' &&
					<MenuList>
						<MenuItem onClick={toggleLayer('diagnostics')}>
							<ListItemIcon>{layers.diagnostics
								? <CheckBox />
								: <EmptyCheck />}</ListItemIcon>
							<ListItemText>Diagnostics</ListItemText>
						</MenuItem>

						<MenuItem onClick={toggleLayer('treatments')}>
							<ListItemIcon>{layers.treatments
								? <CheckBox />
								: <EmptyCheck />}</ListItemIcon>
							<ListItemText>Treatments</ListItemText>
						</MenuItem>

						<MenuItem onClick={toggleLayer('probingValues')}>
							<ListItemIcon>{layers.probingValues
								? <CheckBox />
								: <EmptyCheck />}</ListItemIcon>
							<ListItemText>Probing Values</ListItemText>
						</MenuItem>
					</MenuList>
				}

				{submenu === 'markup' &&
					<MenuList>
						<MenuItem onClick={toggleLayer('text')}>
							<ListItemIcon>{layers.text
								? <CheckBox />
								: <EmptyCheck />}</ListItemIcon>
							<ListItemText>Text</ListItemText>
						</MenuItem>

						<MenuItem onClick={toggleLayer('drawings')}>
							<ListItemIcon>{layers.drawings
								? <CheckBox />
								: <EmptyCheck />}</ListItemIcon>
							<ListItemText>Drawings</ListItemText>
						</MenuItem>

						<MenuItem onClick={toggleLayer('shapes')}>
							<ListItemIcon>{layers.shapes
								? <CheckBox />
								: <EmptyCheck />}</ListItemIcon>
							<ListItemText>Shapes</ListItemText>
						</MenuItem>
					</MenuList>
				}

				{submenu === 'occlusal' &&
					<MenuList>
						<MenuItem onClick={toggleLayer('softTissue')}>
							<ListItemIcon>{layers.softTissue
								? <RadioButtonChecked />
								: <RadioButtonUnchecked />}</ListItemIcon>
							<ListItemText>Soft Tissue</ListItemText>
						</MenuItem>

						<MenuItem onClick={toggleLayer('sublingual')}>
							<ListItemIcon>{layers.sublingual
								? <RadioButtonChecked />
								: <RadioButtonUnchecked />}</ListItemIcon>
							<ListItemText>Sublingual</ListItemText>
						</MenuItem>

						<Divider />

						<MenuItem onClick={toggleLayer('boneView')}>
							<ListItemIcon>{layers.boneView
								? <CheckBox />
								: <EmptyCheck />}</ListItemIcon>
							<ListItemText>Bone View</ListItemText>
						</MenuItem>


					</MenuList>
				}

			</Popover>
		</>
	);
};



export default MenuLayers;
