import React, { useCallback, useEffect, useState } from 'react';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { Place } from '@mui/icons-material';
import DashboardCard from 'components/DashboardCard';
import { Link } from 'react-router-dom';
import api from 'api';
import { enqueueSnackbar } from 'notistack';



const ContactInfo = () => {
	const [ loaded, setLoaded ] = useState(false);
	const [ saving, setSaving ] = useState(false);
	const [ contactData, setContactData ] = useState({
		clinic_address_line_1: '',
		clinic_address_line_2: '',
		clinic_address_city: '',
		clinic_address_state: '',
		clinic_address_postal_code: '',
		clinic_address_country: '',
		clinic_phone: '',
		clinic_email: '',
	});

	const updateContactField = useCallback(e => {
		setContactData((currentValues) => ({
			...currentValues,
			[ e.target.name ]: e.target.value,
		}));
	}, [ setContactData ]);


	const saveContactDetails = useCallback(async () => {
		try {

			await api.put('/clinic-settings/update/contact', { clinic_contact_info: contactData });

			enqueueSnackbar(`Clinic contact details updated!`, {
				variant: 'success',
				autoHideDuration: 2500,
			});
		} catch(err) {
			console.error(err);
			enqueueSnackbar(`There was an error saving the clinic contact details. Please try again.`, {
				variant: 'error',
				autoHideDuration: 2500,
			});
		}
	}, [ setSaving, contactData ]);


	useEffect(() => {
		api.get('/clinic-settings/address-contact-info')
			.then(res => {
				setContactData(res.data?.clinic_contact_info || {});
			})
			.catch(err => console.error(err))
			.finally(() => setLoaded(true));
	}, []);


	return (
		<DashboardCard
			color={'orange'}
			icon={<Place />}
			topContent={"Address and Contact Info"}
			size="xl"
		>
			<Grid container spacing={2} sx={{ marginTop: 1 }}>
				<Grid item xs={12} lg={6}>
					<Typography variant="body1">Address</Typography>
					<Typography variant="body2">
						Please enter the address of your clinic below. This address is able to be included in reports.
					</Typography>
					<Grid item xs={12} sx={{ marginBottom: 1, marginTop: 2 }}>
						<TextField
							placeholder="Address Line 1"
							label="Address Line 1"
							sx={{ width: '428px' }}
							value={contactData.clinic_address_line_1 || ''}
							name="clinic_address_line_1"
							onChange={updateContactField}
						/>
					</Grid>
					<Grid item xs={12} sx={{ marginBottom: 1 }}>
						<TextField
							placeholder="Address Line 2"
							label="Address Line 2"
							sx={{ width: '428px' }}
							value={contactData.clinic_address_line_2 || ''}
							name="clinic_address_line_2"
							onChange={updateContactField}
						/>
					</Grid>
					<Grid item xs={12} sx={{ marginBottom: 1 }}>
						<TextField
							placeholder="City"
							label="City"
							sx={{ marginRight: 1 }}
							value={contactData.clinic_address_city || ''}
							name="clinic_address_city"
							onChange={updateContactField}
						/>
						<TextField
							placeholder="State/Province"
							label="State/Province"
							value={contactData.clinic_address_state || ''}
							name="clinic_address_state"
							onChange={updateContactField}
						/>
					</Grid>
					<Grid item xs={12} sx={{ marginBottom: 1 }}>
						<TextField
							placeholder="ZIP/Postal Code"
							label="ZIP/Postal Code"
							sx={{ marginRight: 1 }}
							value={contactData.clinic_address_postal_code || ''}
							name="clinic_address_postal_code"
							onChange={updateContactField}
						/>
						<TextField
							placeholder="Country"
							label="Country"
							value={contactData.clinic_address_country || ''}
							name="clinic_address_country"
							onChange={updateContactField}
						/>
					</Grid>

				</Grid>

				<Grid item xs={12} lg={6}>
					<Typography variant="body1">Phone Number</Typography>
					<Typography variant="body2">
						Please enter the phone number for your clinic. This number is able to be included in reports.
					</Typography>
					<TextField
						placeholder="Enter your clinic's phone number"
						label="Phone number"
						value={contactData.clinic_phone}
						name="clinic_phone"
						onChange={updateContactField}
						sx={{ marginTop: '16px' }}
					/>
				</Grid>

				<Grid item xs={12} lg={6} sx={{ marginTop: 2 }}>
					<Typography variant="body1">Email Address</Typography>
					<Typography variant="body2">
						Please enter the email address for your clinic. This email address is able to be included in
						reports.<br />
						Visit the <Link to="/clinic-settings/billing">billing settings</Link> page to set a billing
						email address.
					</Typography>
					<TextField
						placeholder="Enter your clinic's email address"
						label="Email address"
						sx={{ width: '428px', marginTop: '16px' }}
						value={contactData.clinic_email}
						name="clinic_email"
						onChange={updateContactField}
					/>
				</Grid>

				<Grid item xs={12} sx={{ marginTop: 2 }}>
					<Button
						variant="contained"
						disabled={!loaded || saving}
						onClick={saveContactDetails}
					>
						Save Contact Details
					</Button>
				</Grid>
			</Grid>

		</DashboardCard>
	);
};

export default ContactInfo;
